import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SEO from '../../components/common/seo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      margin: theme.spacing(1),
    },
    card: {
      padding: theme.spacing(1),
      margin: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    centered: {
      maxWidth: '640px',
      margin: 'auto',
    },
    action: {
      margin: theme.spacing(1),
    },
    link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  }),
);

const DownloadPage = () => {
  const classes = useStyles();

  return (
    <>
      <SEO title="NavAbility SDK Download" />
      <div className={classes.root}>
        <Card>
          <CardHeader
            title="NavAbility SDKs"
            subheader="Easily access your data and integrate your robotics with NavAbility using our SDKs"
          />
          <CardContent style={{ padding: '0px', margin: '20px' }}>
            <Typography>
              We actively support access to our API with Python, Javascript, and Julia SDKs. If you'd like support for
              another language, please don't hesitate to reach out to{' '}
              <a href="mailto:info@navability.io">info@navability.io</a>
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Python
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Find us on{' '}
              <a className={classes.link} target="_blank" href="https://pypi.org/search/?q=navabilitysdk">
                {' '}
                PyPi{' '}
              </a>
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              <code> pip install navabilitysdk </code>
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              JavaScript / TypeScript
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Find us on{' '}
              <a className={classes.link} target="_blank" href="https://www.npmjs.com/package/navabilitysdk">
                {' '}
                npm{' '}
              </a>
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              <code> npm install navabilitysdk </code>
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Julia
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Find us on the{' '}
              <a
                className={classes.link}
                target="_blank"
                href="https://github.com/JuliaRegistries/General/tree/master/N/NavAbilitySDK"
              >
                {' '}
                Julia Registry{' '}
              </a>
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              <code> Pkg.add("NavAbilitySDK") </code>
            </Typography>
          </CardContent>
        </Card>
        {/* <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              For future updates:
            </Typography>
            <Typography gutterBottom variant="body1" component="p">
              Follow the{' '}
              <a className={classes.link} target="_blank" href="https://www.navability.io/blog-2022/">
                {' '}
                NavAbility Blog{' '}
              </a>
              .
            </Typography>
          </CardContent>
        </Card> */}
      </div>
    </>
  );
};

export default DownloadPage;
